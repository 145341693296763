import { Component, Input } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Lien } from '../../interfaces/liens/lien';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { LienDetailsUIConfiguration } from '../../interfaces/ui-configurations/lien-details-ui-configuration';

@Component({
  selector: 'lib-lien-details',
  templateUrl: './lien-details.component.html',
  styleUrls: ['./lien-details.component.css']
})
export class LienDetailsComponent {
  @Input() uiConfiguration!: LienDetailsUIConfiguration;
  order!: Order;
  lien!: Lien | null | undefined;
  private unsubscribe$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService) {}

  ngOnInit(): void {
    this.orderManager.currentOrder
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(order => {
      this.order = order
      this.lien = order.lien;
    });
  }
  
  ngOnDestroy(): void {
      // Signal all subscriptions to complete
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  protected getRIN(): string {
    if(this.order.lien?.systemGeneratedRIN) {
      return 'System Generated';
    } else if(this.order.lien?.noRIN) {
      return 'No RIN';
    } else {
      return this.order.lien?.rin ?? '';
    }
  }
}