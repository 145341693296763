import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AdminAuthService } from '../../../services/auth/admin-auth.service';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.css']
})
export class LoggedOutComponent {
  constructor(private _authService: AdminAuthService, private _router: Router) { }
  ngOnInit(): void {
    
  }
}
