<h4>External Order Details</h4>
<div *ngIf="isLockedByMe; else lockedBySomoneElse">
    <div class="flex-container">
        <mat-form-field appearance="outline" class="form-field">
            <mat-label>External Number</mat-label>
            <input matInput name="externalNumber" [(ngModel)]="this.externalOrderNumber!.number"
                placeholder="External Number">
        </mat-form-field>
    </div>
    <div class="flex-container">
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>Date Received</mat-label>
            <input matInput [matDatepicker]="dateReceived" [(ngModel)]="this.externalOrderNumber!.dateReceived"
                placeholder="YYYY-MM-DD">
            <mat-datepicker-toggle matSuffix [for]="dateReceived"></mat-datepicker-toggle>
            <mat-datepicker #dateReceived></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="flex-container">
        <button mat-raised-button (click)="saveExternalOrderNumber()" color="primary"
            class="padding-sides flex-item form-field" aria-label="Save External Order Number">
            Save
        </button>
    </div>
</div>
<ng-template #lockedBySomoneElse>
    <div class="flex-container" *ngIf="showExternalDetails()">
        <span class="flex-item">External Number:</span>
        <span class="flex-item-2">{{ externalOrderNumber!.number }}</span>
    </div>
    <div class="flex-container" *ngIf="showExternalDetails()">
        <span class="flex-item">Date Received:</span>
        <span class="flex-item-2">{{ externalOrderNumber!.dateReceived | pacificDate:'yyyy-MM-dd HH:mm' }}</span>
    </div>
</ng-template>