<div class="padding">
    <mat-card>
        <mat-card-content>
            <div class="table-container" *ngIf="dataSource && dataSource.data.length > 0">
                <h3>Databases Overview</h3>

                <!-- Databases overview table -->
                <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows
                    *ngIf="dataSource.data.length > 0">

                    <ng-container matColumnDef="databaseName">
                        <th mat-header-cell *matHeaderCellDef id="databaseName" mat-sort-header
                            sortActionDescription="Sort by database name."> Database Name </th>
                        <td mat-cell *matCellDef="let datbaseOverview"> {{datbaseOverview.databaseName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="numPendings">
                        <th mat-header-cell *matHeaderCellDef id="numPendings" mat-sort-header
                            sortActionDescription="Sort by number of pending orders."> Pendings </th>
                        <td mat-cell *matCellDef="let databaseOverview">
                            <button mat-raised-button [disabled]="databaseOverview.numPendings === 0"
                                [ngStyle]="getButtonColor(databaseOverview.numPendings)"
                                (click)="onViewPendings(databaseOverview)">
                                {{ databaseOverview.numPendings }}
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numHighVolume">
                        <th mat-header-cell *matHeaderCellDef id="numHighVolume" mat-sort-header
                            sortActionDescription="Sort by number of High Volume orders."> High Volume </th>
                        <td mat-cell *matCellDef="let databaseOverview">
                            <button mat-raised-button [disabled]="databaseOverview.numHighVolume === 0"
                                [ngStyle]="getButtonColor(databaseOverview.numHighVolume)"
                                (click)="onViewHighVolumn(databaseOverview)">
                                {{ databaseOverview.numHighVolume }}
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numRequestedAtRegistry">
                        <th mat-header-cell *matHeaderCellDef id="numRequestedAtRegistry" mat-sort-header
                            sortActionDescription="Sort by number of Requested at Registry orders."> Requested at Registry </th>
                        <td mat-cell *matCellDef="let databaseOverview">
                            <button mat-raised-button [disabled]="databaseOverview.numRequestedAtRegistry === 0"
                                [ngStyle]="getButtonColor(databaseOverview.numRequestedAtRegistry)"
                                (click)="onViewRequestedAtRegistry(databaseOverview)">
                                {{ databaseOverview.numRequestedAtRegistry }}
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numOutOfRegistryHours">
                        <th mat-header-cell *matHeaderCellDef id="numOutOfRegistryHours" mat-sort-header
                            sortActionDescription="Sort by number of Out of Registry Hours orders."> Out of Registry Hours </th>
                        <td mat-cell *matCellDef="let databaseOverview">
                            <button mat-raised-button [disabled]="databaseOverview.numOutOfRegistryHours === 0"
                                [ngStyle]="getButtonColor(databaseOverview.numOutOfRegistryHours)"
                                (click)="onViewOutOfHours(databaseOverview)">
                                {{ databaseOverview.numOutOfRegistryHours }}
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

            <lib-placeholder iconName="cancel" iconClass="icon-warning" message="Error loading database overview"
                [condition]="this.dataSource.data.length === 0"></lib-placeholder>

        </mat-card-content>
    </mat-card>
</div>