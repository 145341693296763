import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-automation-monitors',
  templateUrl: './automation-monitors.component.html',
  styleUrls: ['./automation-monitors.component.css']
})
export class AutomationMonitorsComponent {

  @Input() public loading: boolean = false;
  
  constructor() { }

}