<div class="margin-bottom-5">
    <div class="flex-container" *ngIf="uiConfiguration.showQCFormTypeID">
        <span class="flex-item-3">Form Type:</span>
        <span class="flex-item-4">{{ lien?.qcFormTypeID }}</span>
    </div>
    <div *ngIf="(uiConfiguration.showTerm || uiConfiguration.showInfinityTerm) && (!lien?.term || !lien?.isInfiniteTerm)" class="flex-container">
        <span class="flex-item-3">Term:</span>
        <span class="flex-item-4">{{ lien?.isInfiniteTerm ? "INFINITY" : order.lien?.term }}</span>
    </div>
    <div *ngIf="uiConfiguration.showExpiryDate && !lien?.term && !lien?.isInfiniteTerm" class="flex-container">
        <span class="flex-item-3">Expiry Date:</span>
        <span class="flex-item-4">{{ lien?.expiryDate | pacificDate: 'yyyy-MM-dd' }}</span>
    </div>
    <div *ngIf="uiConfiguration.showAmount && lien?.amount" class="flex-container">
        <span class="flex-item-3">Amount:</span>
        <span class="flex-item-4">{{ lien?.amount | currency }}</span>
    </div>
    <div *ngIf="uiConfiguration.showDischargeType && order?.discharge?.dischargeType" class="flex-container">
        <span class="flex-item-3">Discharge Type:</span>
        <span class="flex-item-4">{{ order.discharge?.dischargeType }}</span>
    </div>
    <div class="flex-container" *ngIf="(uiConfiguration.showSigningCity ||
                                    uiConfiguration.showSigningJurisdiction ||
                                    uiConfiguration.showSigningCountry) && 
                                    (lien?.signingCity || 
                                    lien?.signingJurisdiction || 
                                    lien?.signingCountry) ">
        <span class="flex-item-3">Signing Location:</span>
        <span class="flex-item-4">{{ lien?.signingCity }} {{ lien?.signingJurisdiction }} {{ lien?.signingCountry }}
        </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showSigningDate && lien?.signingDate">
        <span class="flex-item-3">Signing Date:</span>
        <span class="flex-item-4">{{ lien?.signingDate | pacificDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showSurrenderDate && lien?.surrenderDate">
        <span class="flex-item-3">Surrender Date:</span>
        <span class="flex-item-4">{{ lien?.surrenderDate | pacificDate: "yyyy-MM-dd" }} </span>
    </div>
    <div *ngIf="uiConfiguration.showStillHavePosession" class="flex-container">
        <span class="flex-item-3">Still Have Posession:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.stillHavePosession ?? false"></mat-checkbox></span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showVehicleReleasedDate && lien?.vehicleReleasedDate">
        <span class="flex-item-3">Vehicle Released Date:</span>
        <span class="flex-item-4">{{ lien?.vehicleReleasedDate | pacificDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showWorkLastCompletedDate && lien?.workLastCompletedDate">
        <span class="flex-item-3">Work Last Completed Date:</span>
        <span class="flex-item-4">{{ lien?.workLastCompletedDate | pacificDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showPartsProvidedDate && lien?.partsProvidedDate">
        <span class="flex-item-3">Parts Provided Date:</span>
        <span class="flex-item-4">{{ lien?.partsProvidedDate | pacificDate: "yyyy-MM-dd" }} </span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showRIN">
        <span class="flex-item-3">RIN:</span>
        <span class="flex-item-4">{{ getRIN() }}</span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showSumOfHypothec">
        <span class="flex-item-3">Sum of Hypothec:</span>
        <span class="flex-item-4">{{ lien?.sumOfHypothec }}</span>
    </div>
    <div *ngIf="uiConfiguration.showPerfectionInAnotherJurisdiction" class="flex-container">
        <span class="flex-item-3">Perfection in Another Jurisdiction:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.perfectionInAnotherJurisdiction ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showPurchaseMoneySecurityInterest" class="flex-container">
        <span class="flex-item-3">Purchase Money Security Interest:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.purchaseMoneySecurityInterest ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showTrustIndenture" class="flex-container">
        <span class="flex-item-3">Trust Indenture:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.trustIndenture ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showReceiverAppointment" class="flex-container">
        <span class="flex-item-3">Receiver Appointment:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.receiverAppointment ?? false"></mat-checkbox></span>
    </div>
    <div class="flex-container" *ngIf="uiConfiguration.showMaturityDate && order.lien?.maturityDate ?? false">
        <span class="flex-item-3">Maturity Date:</span>
        <span class="flex-item-4">{{ order.lien?.maturityDate | pacificDate: "yyyy-MM-dd" }}</span>
    </div>
    <div *ngIf="uiConfiguration.showMaturityDate" class="flex-container">
        <span class="flex-item-3">No Fixed Maturity Date:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.noFixedMaturityDate ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showConsumerGoods" class="flex-container">
        <span class="flex-item-3">Consumer Goods:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.consumerGoods ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showInventory" class="flex-container">
        <span class="flex-item-3">Inventory:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.inventory ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showEquipment" class="flex-container">
        <span class="flex-item-3">Equipment:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.equipment ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showAccounts" class="flex-container">
        <span class="flex-item-3">Accounts:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.accounts ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showOther" class="flex-container">
        <span class="flex-item-3">Other:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.other ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showMVIncluded" class="flex-container">
        <span class="flex-item-3">MVIncluded:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.mvIncluded ?? false"></mat-checkbox></span>
    </div>
    <div *ngIf="uiConfiguration.showCautionFiling" class="flex-container">
        <span class="flex-item-3">Caution Filing:</span>
        <span class="flex-item-4"><mat-checkbox disabled="true"
                [checked]="order.lien?.cautionFiling ?? false"></mat-checkbox></span>
    </div>
</div>
<div *ngIf="uiConfiguration.showAdditionalInformation && order.lien?.additionalInformation" class="flex-item">
    <div class="bold">Additional Information:</div>
    <div class="preserve-line-breaks">{{ order.lien?.additionalInformation }}</div>
</div>
<div *ngIf="uiConfiguration.showCourtOrder && order.lien?.courtOrder" class="flex-item">
    <div class="bold">Court Order:</div>
    <div class="preserve-line-breaks">{{ order.lien?.courtOrder }}</div>
</div>
<div *ngIf="uiConfiguration.showOtherChanges && order.lien?.otherChanges" class="flex-item">
    <div class="bold">Other Changes:</div>
    <div class="preserve-line-breaks">{{ order.lien?.otherChanges }}</div>
</div>