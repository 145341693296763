import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AdminAuthService } from '../../../services/auth/admin-auth.service';
import { User } from 'oidc-client-ts';

@Component({
  selector: 'app-top-menubar',
  templateUrl: './top-menubar.component.html',
  styleUrls: ['./top-menubar.component.css']
})
export class TopMenubarComponent {

  @Input() isSidenavOpen: boolean = true;
  @Output() isSidenavOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  username: string = "";
  userAuthenticated: boolean = false;

  constructor(private router: Router,
    private authService: AdminAuthService) {}


  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.userAuthenticated = true;
      this.username = this.authService.getUserNameFromToken() ?? "";
    }
  }

  toggleSidenav() {
    this.isSidenavOpen = !this.isSidenavOpen;
    this.isSidenavOpenChange.emit(this.isSidenavOpen);
  }

  navigateHome() {
    this.router.navigate(['/dashboard']);
  }

  navigateToMyAccount() {
    this.router.navigate(['/my-account']);
  }

  logIn(): void {
    this.authService.logIn()
  }

  logOut() {
    this.authService.logOut();
  }
}
