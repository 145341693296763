import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ExternalOrderNumber } from '../../interfaces/external-order-number/external-order-number';

@Component({
  selector: 'lib-external-order-details',
  templateUrl: './external-order-details.component.html',
  styleUrls: ['./external-order-details.component.css']
})
export class ExternalOrderDetailsComponent implements OnInit {
  @Input() externalOrderDetails: ExternalOrderNumber | null = null;
  @Input() isLockedByMe: boolean = false;
  @Output() saveExternalOrderNumberEvent = new EventEmitter<ExternalOrderNumber>();

  externalOrderNumber: ExternalOrderNumber = {
    number: "",
    dateReceived: new Date()
  };

  constructor() {}

  ngOnInit(): void {
    if (this.externalOrderDetails != null && this.externalOrderDetails != undefined) {
      this.externalOrderNumber = this.externalOrderDetails;
    }
  }

  showExternalDetails(): boolean {
    return ((this.externalOrderDetails?.number.length ?? 0) > 0)
  }

  saveExternalOrderNumber() {
    this.saveExternalOrderNumberEvent.emit(this.externalOrderNumber!);
  }
}
