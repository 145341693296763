import { Component } from '@angular/core';
import { ScheduledMonitorsRepositoryService } from '../../../services/scheduled-monitors/scheduled-monitors-repository.service';
import { AutomationMonitorComponent } from '../base-monitor-component';

@Component({
  selector: 'app-scheduled-monitors',
  templateUrl: './scheduled-monitors.component.html',
  styleUrls: ['./scheduled-monitors.component.css']
})
export class ScheduledMonitorsComponent extends AutomationMonitorComponent {

  protected displayedColumns = [
    'icon',
    'automationName',
    'scheduledFrequency',
    'warningFrequency',
    'lastSuccessfulRunDate'
  ]

  constructor(repo: ScheduledMonitorsRepositoryService) { 
    super(repo);
  }
}
