import { Injectable } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { eOrderType } from '../../enums';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderManagerService {
  private initialOrder: Order = {
    id: "",
    referenceNumber: "",
    country: "",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet,
  } as Order;

  private initialParentOrder: Order = {
    id: "",
    referenceNumber: "",
    country: "",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet,
  } as Order;

  // BehaviorSubject to hold the current state of the order
  currentOrder = new BehaviorSubject<Order>(this.initialOrder);
  parentOrder = new BehaviorSubject<Order>(this.initialParentOrder);

  constructor() { }

  // Method to update the order
  updateOrder(order: Order) {
    this.currentOrder.next(order);
  }

  updateLien(values: any) {
    const currentOrder = this.currentOrder.value;

    for (const key in values) {
      if (key in currentOrder.lien!) {
        (currentOrder.lien! as any)[key] = values[key];
      }
    }

    this.currentOrder.next(currentOrder);
  }

  // Method to update the order
  updateParentOrder(order: Order) {
    this.parentOrder.next(order);
  }

  clearOrder() {
    this.currentOrder.next(this.initialOrder);
  }

  clearParentOrder() {
    this.parentOrder.next(this.initialParentOrder);
  }

  getCurrentOrderValueForPut(): any {
    const order = this.currentOrder.value;

    if(order.orderTypeID === eOrderType.renewal ||
        order.orderTypeID === eOrderType.discharge) {
          return this.mapOrderToBaseDetails(order);
        }

    return order;
  }

  mapOrderToBaseDetails(order: Order) {
    return {
      id: order.id,
      referenceNumber: order.referenceNumber,
      orderTypeID: order.orderTypeID,
      jurisdiction: order.jurisdiction,
      country: order.country,
      term: order.lien?.term,
      isInfiniteTerm: order.lien?.isInfiniteTerm,
      expiryDate: order.lien?.expiryDate,
      discharge: order.discharge
    }
  }
}
