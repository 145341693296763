import { Component } from '@angular/core';
import { QueueMonitorsRepositoryService } from '../../../services/queue-monitors/queue-monitors-repository.service';
import { AutomationMonitorComponent } from '../base-monitor-component';

@Component({
  selector: 'app-queue-monitors',
  templateUrl: './queue-monitors.component.html',
  styleUrls: ['./queue-monitors.component.css']
})
export class QueueMonitorsComponent extends AutomationMonitorComponent {
  
  protected displayedColumns = [
    'icon',
    'automationName',
    'currentQueueSize',
    'maxQueueSize',
    'lastSuccessfulRunDate'
  ]

  constructor(repo: QueueMonitorsRepositoryService) { 
    super(repo);
  }
}
