import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AdminAuthService } from '../../../services/auth/admin-auth.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent {
  constructor(private _authService: AdminAuthService, private _router: Router) { }
  ngOnInit(): void {
    if (this._authService.isLoggedIn()) {
      this._router.navigate(['/dashboard']);
    }
  }
}
