import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatabaseOverview } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseOverviewService {

  constructor(
    private http: HttpClient,
    private urlService: EnvironmentUrlService) { }

  public getDatabaseOverviews(): Observable<DatabaseOverview[]> {
    return this.http.get<DatabaseOverview[]>(`${this.urlService.urlAddress}/DatabaseOverview`);
  }
}
