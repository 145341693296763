import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Order } from '../../interfaces/orders/order';
import { ReferenceRegistrationInformation } from '../../interfaces/reference-registration-information/reference-registration-information';
import { ReferenceRegistrationInformationUIConfiguration } from '../../interfaces/ui-configurations/reference-registration-information-ui-configuration';

@Component({
    selector: 'lib-existing-registration-details',
    templateUrl: './existing-registration-details.component.html',
    styleUrls: ['./existing-registration-details.component.css']
})
export class ExistingRegistrationDetailsComponent implements OnInit, OnDestroy {
    @Input() uiConfiguration!: ReferenceRegistrationInformationUIConfiguration;
    order!: Order;
    referenceRegistrationDetails!: ReferenceRegistrationInformation | null | undefined;
    private unsubscribe$ = new Subject<void>();

    constructor(private orderManager: OrderManagerService) { }

    ngOnInit(): void {
        this.orderManager.currentOrder
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(order => {
                this.order = order
                this.referenceRegistrationDetails = order.referenceRegistrationInformation;
            });
    }

    ngOnDestroy(): void {
        // Signal all subscriptions to complete
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}