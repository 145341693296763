import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent {
  @Input() messages: string[] = [];
}
