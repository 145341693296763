import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { ReusableParty } from '../../interfaces/reusable-parties/reusable-party';
import { ReusablePartyForCreation } from '../../interfaces/reusable-parties/reusable-party-for-creation';
import { ReusablePartyForUpdate } from '../../interfaces/reusable-parties/reusable-party-for-update';

@Injectable({
    providedIn: 'root'
})
export class ReusablePartyRepositoryService extends PaginatedRepository<ReusableParty> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        
        super(http,messageService);

        this._currentParams = new HttpParams()
            .set('pageSize', 10);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/ReusableParties`;
        this._baseLink = this._baseLinkTemplate;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public createReusableParty = (corporationID: string, reusableParty: ReusablePartyForCreation) => {
        return this.http.post<ReusableParty>(
            `${this.config.rootUrl}/Corporations/${corporationID}/ReusableParties`,
            reusableParty,
            this.generateHeaders());
    }

    public getReusableParty = (corporationID: string, reusablePartyID: string) => {
        return this.http.get<ReusableParty>(
            `${this.config.rootUrl}/Corporations/${corporationID}/ReusableParties/${reusablePartyID}`,
            this.generateHeaders());
    }

    public updateReusableParty = (corporationID: string, reusablePartyID: string, reusableParty: ReusablePartyForUpdate) => {
        return this.http.put<ReusableParty>(
            `${this.config.rootUrl}/Corporations/${corporationID}/ReusableParties/${reusablePartyID}`,
            reusableParty,
            this.generateHeaders());
    }

    public deleteReusableParty = (corporationID: string, reusablePartyID: string) => {
        return this.http.delete(
            `${this.config.rootUrl}/Corporations/${corporationID}/ReusableParties/${reusablePartyID}`,
            this.generateHeaders());
    }
}
