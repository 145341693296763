import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InternalSearchCriteria } from '../../interfaces/internal-search-criteria';

@Component({
  selector: 'lib-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {
  searchOptions = [
    { value: 'businessName', label: 'Business Name' },
    { value: 'dateOfBirth', label: 'Date of Birth' },
    { value: 'firstName', label: 'First Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'orderId', label: 'OrderID' },
    { value: 'referenceNumber', label: 'Reference Number' },
    { value: 'registrationNumber', label: 'Registration Number' },
    { value: 'serialNumber', label: 'Serial Number' }
  ];

  protected searchCriteria: string = '';
  protected typeOfSearch: string = 'referenceNumber';

  @Output() search = new EventEmitter<InternalSearchCriteria>();

  constructor() { }

  protected performSearch() {
    this.search.emit({
      typeOfSearch: this.typeOfSearch,
      criteria: this.searchCriteria,
    });

    this.searchCriteria = ''
  }
}