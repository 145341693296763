import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { Router } from '@angular/router';
import { DatabaseOverview } from 'reg-hub-common';

@Component({
  selector: 'app-database-overview',
  templateUrl: './database-overview.component.html',
  styleUrls: ['./database-overview.component.css']
})
export class DatabaseOverviewComponent implements AfterViewInit {

  @ViewChild(MatSort) sort!: MatSort;
  
  @Input() dataSource!: MatTableDataSource<DatabaseOverview, MatTableDataSourcePaginator>;
  @Input() loading: boolean = false;

  protected displayedColumns = [   
    'databaseName',
    'numPendings',
    'numHighVolume',
    'numRequestedAtRegistry',
    'numOutOfRegistryHours'
   ];
  
  constructor(private router: Router) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  protected getButtonColor(numOrders: number) {
    let value = 255 - numOrders * 5;

    if(value >= 205 && numOrders !== 0) {
      value = 205;
    } else if(value < 0) {
      value = 0;
    }

    return { 'background-color': `rgb(255, ${value}, ${value})` };
  }

  protected onViewPendings(overview: DatabaseOverview) {
    const params = {
      status: 'Pending',
      databaseID: overview.databaseID,
      isVisible: true,
      sortColumn: 'added',
      sortDirection: 'desc'
    }

    this.router.navigate(['/orders'], { queryParams: params });
  }

  protected onViewDrafts(overview: DatabaseOverview) {
    const params = {
      status: 'Draft',
      databaseID: overview.databaseID,
      isVisible: true,
      sortColumn: 'priority',
      sortDirection: 'asc'
    }

    this.router.navigate(['/orders'], { queryParams: params });
  }

  protected onViewHighVolumn(overview: DatabaseOverview) {
    const params = {
      status: 'AwaitingHighVolumeResults',
      databaseID: overview.databaseID,
      isVisible: true,
      sortColumn: 'priority',
      sortDirection: 'asc'
    }

    this.router.navigate(['/orders'], { queryParams: params });
  }

  protected onViewRequestedAtRegistry(overview: DatabaseOverview) {
    const params = {
      status: 'RequestedAtRegistry',
      databaseID: overview.databaseID,
      isVisible: true,
      sortColumn: 'priority',
      sortDirection: 'asc'
    }

    this.router.navigate(['/orders'], { queryParams: params });
  }

  protected onViewOutOfHours(overview: DatabaseOverview) {
    const params = {
      status: 'OutOfRegistryHours',
      databaseID: overview.databaseID,
      isVisible: true,
      sortColumn: 'priority',
      sortDirection: 'asc'
    }

    this.router.navigate(['/orders'], { queryParams: params });
  }
}
