import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { CountryInfo } from '../../interfaces/jurisdictions/country-info';
import { JurisdictionInfo } from '../../interfaces/jurisdictions/jurisdiction-info';
import { OrderTypeJurisdictions } from '../../interfaces/jurisdictions/order-type-jurisdictions';
import { FeeType } from '../../interfaces/enum-types/fee-type';
import { OrderStatusType } from '../../interfaces/enum-types/order-status-type';
import { OrderType } from '../../interfaces/enum-types/order-type';
import { SelectOption } from '../../interfaces/select-option';
import { DocumentType } from '../../interfaces/enum-types/document-type';
import { ResultState } from '../../interfaces/enum-types/result-state';
import { LogSeverity } from '../../interfaces/enum-types/log-severity';
import { eGeneration, eOrderType, eOrderTypeGroup, eQCFormType } from '../../enums';
import { AssetType } from '../../interfaces/enum-types/assetType';
import { BillingCycle } from '../../interfaces/enum-types/billing-cycle';
import { SystemEventType } from '../../interfaces/enum-types/system-event-type';
import { PartyType } from '../../interfaces/enum-types/party-type';
import { QCFormType } from '../../interfaces/enum-types/qc-form-type';
import { DatabaseType } from '../../interfaces/enum-types/database-type';
import { Generation } from '../../interfaces/generation/generation';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
  providedIn: 'root'
})
export class EnumeratedTypesRepositoryService {

  private countryInfos: CountryInfo[] | null = null;
  private assetTypes: AssetType[] | null = null;
  private qcFormTypes: QCFormType[] | null = null;

  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) { }


  public getOrderTypes(baseUrl: string, orderTypeGroup?: eOrderTypeGroup, defaultParams?: HttpParams): Observable<OrderType[]> {
    var requestUrl = `${baseUrl}/OrderTypes`;
    if (orderTypeGroup) {
      requestUrl = `${requestUrl}?OrderTypeGroupID=${orderTypeGroup}`;
    }

    return this.http.get<OrderType[]>(requestUrl, { params: defaultParams });
  }

  public getOrderTypesAsSelectOptions(baseUrl: string, orderTypeGroup?: eOrderTypeGroup): Observable<SelectOption[]> {
    let params = new HttpParams().set('PageSize', 100);    
    return this.getOrderTypes(baseUrl, orderTypeGroup, params).pipe(map(types => types.map(this.mapOrderTypeToSelectOption)));
  }

  private mapOrderTypeToSelectOption(type: OrderType): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getOrderStatusTypes(baseUrl: string, defaultParams?: HttpParams): Observable<OrderStatusType[]> {
    return this.http.get<OrderStatusType[]>(`${baseUrl}/OrderStatusTypes`, { params: defaultParams });
  }

  public getOrderStatusTypesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    let params = new HttpParams().set('PageSize', 100);  
    return this.getOrderStatusTypes(baseUrl, params).pipe(map(types => types.map(this.mapOrderStatusTypeToSelectOption)));
  }

  private mapOrderStatusTypeToSelectOption(type: OrderStatusType): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getCountryInfo(baseUrl: string): Observable<CountryInfo[]> {
    if (!this.countryInfos) {
      return this.http.get<CountryInfo[]>(`${baseUrl}/Jurisdictions`)
        .pipe(tap(countryInfos => this.countryInfos = countryInfos));
    } else {
      return of(this.countryInfos);
    }
  }

  public getCountryInfoAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getCountryInfo(baseUrl).pipe(map(infos => infos.map(info => ({
      label: info.countryFullName,
      value: info.countryCode
    }))));
  }

  public getJurisdictionInfo(baseUrl: string, country?: string): Observable<JurisdictionInfo[]> {
    return this.filterAndMapJurisdictions(this.getCountryInfo(baseUrl), country);
  }

  public getJurisdictionInfoAsSelectOptions(baseUrl: string, country: string): Observable<SelectOption[]> {
    return this.getJurisdictionInfo(baseUrl, country).pipe(map(infos => infos.map(info => ({
      label: info.jurisdictionFullName,
      value: info.jurisdictionCode
    }))));
  }

  public getOrderTypeJurisdictions(baseUrl: string, orderTypes?: eOrderType[]): Observable<OrderTypeJurisdictions[]> {
    let params = new HttpParams();
    if (orderTypes) {
      orderTypes.forEach(id => {
        params = params.append('OrderTypeIDs', id.toString());
      });
    }

    return this.http.get<OrderTypeJurisdictions[]>(`${baseUrl}/Jurisdictions/Orders`, { params });
  }

  public getAssetTypes(orderTypeID: eOrderType, jurisdiction: string): Observable<AssetType[]> {
    return this.http.get<AssetType[]>(`${this.config.rootUrl}/AssetTypes/${orderTypeID}/${jurisdiction}`)
      .pipe(tap(assetTypes => this.assetTypes = assetTypes));
  }

  public getAssetTypesAsSelectOptions(orderTypeID: eOrderType, jurisdiction: string): Observable<SelectOption[]> {
    return this.getAssetTypes(orderTypeID, jurisdiction).pipe(map(infos => infos.map(info => ({
      label: info.name,
      value: info.id
    }))));
  }

  private filterAndMapJurisdictions(countryInfos: Observable<CountryInfo[]>, country?: string): Observable<JurisdictionInfo[]> {
    return countryInfos.pipe(
      map(countryInfos => countryInfos.filter(info => country ? info.countryCode === country : false)),
      map(countryInfos => countryInfos.flatMap(info => info.jurisdictions)))
  }

  public getFeeTypes(baseUrl: string): Observable<FeeType[]> {
    return this.http.get<FeeType[]>(`${baseUrl}/FeeTypes`);
  }

  public getFeeTypesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getFeeTypes(baseUrl).pipe(map(types => types.map(this.mapFeeTypeToSelectOption)));
  }

  private mapFeeTypeToSelectOption(type: FeeType): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getDocumentTypes(baseUrl: string): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(`${baseUrl}/DocumentTypes`);
  }

  public getDocumentTypesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getDocumentTypes(baseUrl).pipe(map(types => types.map(this.mapDocumentTypeToSelectOption)));
  }

  private mapDocumentTypeToSelectOption(type: DocumentType): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getResultStates(baseUrl: string): Observable<ResultState[]> {
    return this.http.get<ResultState[]>(`${baseUrl}/ResultStates`);
  }

  public getResultStatesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getResultStates(baseUrl).pipe(map(types => types.map(this.mapResultStateToSelectOption)));
  }

  private mapResultStateToSelectOption(type: ResultState): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getLogSeverities(baseUrl: string): Observable<ResultState[]> {
    return this.http.get<LogSeverity[]>(`${baseUrl}/LogSeverities`);
  }

  public getLogSeveritiesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getLogSeverities(baseUrl).pipe(map(types => types.map(this.mapLogSeveritiesToSelectOption)));
  }

  private mapLogSeveritiesToSelectOption(type: ResultState): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getBillingCycles(baseUrl: string): Observable<BillingCycle[]> {
    return this.http.get<BillingCycle[]>(`${baseUrl}/BillingCycles`);
  }

  public getBillingCyclesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getBillingCycles(baseUrl).pipe(map(types => types.map(this.mapBillingCyclesToSelectOption)));
  }

  private mapBillingCyclesToSelectOption(type: BillingCycle): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getSystemEventTypes(baseUrl: string): Observable<SystemEventType[]> {
    return this.http.get<SystemEventType[]>(`${baseUrl}/SystemEventTypes`);
  }

  public getSystemEventTypesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getSystemEventTypes(baseUrl).pipe(map(types => types.map(this.mapSystemEventTypeToSelectOption)));
  }

  private mapSystemEventTypeToSelectOption(type: SystemEventType): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getPartyTypes(baseUrl: string): Observable<PartyType[]> {
    return this.http.get<PartyType[]>(`${baseUrl}/PartyTypes`);
  }

  public getPartyTypesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getPartyTypes(baseUrl).pipe(map(types => types.map(this.mapPartyTypeToSelectOption)));
  }

  private mapPartyTypeToSelectOption(type: PartyType): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getQcFormTypes(baseUrl: string, formIds?: eQCFormType[]): Observable<QCFormType[]> {
    if (!this.qcFormTypes) {
      let params = new HttpParams();
      if (formIds) {
        formIds.forEach(id => {
          params = params.append('FormTypes', id.toString());
        });
      }

      return this.http.get<QCFormType[]>(`${baseUrl}/QCFormTypes`, { params })
        .pipe(tap(qcFormTypes => this.qcFormTypes = qcFormTypes));
    } else {
      return of(this.qcFormTypes);
    }
  }

  public getQcFormTypesAsSelectOptions(baseUrl: string, formIds?: eQCFormType[]): Observable<SelectOption[]> {
    return this.getQcFormTypes(baseUrl, formIds).pipe(map(infos => infos.map(info => ({
      label: info.name,
      value: info.id
    }))));
  }

  public getDatabaseTypes(baseUrl: string): Observable<DatabaseType[]> {
    return this.http.get<DatabaseType[]>(`${baseUrl}/DataBaseTypes`);
  }

  public getDatabaseTypesAsSelectOptions(baseUrl: string): Observable<SelectOption[]> {
    return this.getDatabaseTypes(baseUrl).pipe(map(types => types.map(this.mapDatabaseTypeToSelectOption)));
  }

  private mapDatabaseTypeToSelectOption(type: DatabaseType): SelectOption {
    return { label: type.name, value: type.id };
  }

  public getGenerations(): Observable<SelectOption[]> {
    return this.http.get<Generation[]>(`${this.config.rootUrl}/Generations`)
      .pipe(map(generations => generations.map(gen => ({ 
          label: gen.name, 
          value: gen.id }))));
  }
}
