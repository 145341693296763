import { Injectable } from '@angular/core';
import { AuthService } from 'reg-hub-common';
import { authConfig } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthService extends AuthService {
  constructor() { 
    super();
    this.setClientInfo(authConfig);
  }
}
