import { getLocaleExtraDayPeriods } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Order, OrderRepositoryService } from 'reg-hub-common';

@Component({
  selector: 'app-manual-orders',
  templateUrl: './manual-orders.component.html'
})
export class ManualOrdersComponent {

  @Input() data: Order[] = [];
  @Input() total: number = 0;
  @Input() loading: boolean = false;

  protected displayedColumns = [
    'referenceNumber',
    'status',
    'orderTypeID',
    'jurisdiction',
    'messages'
  ]

  constructor(
    protected repo: OrderRepositoryService,
    protected router: Router) { }

  protected onViewAll() {
    let params = {
      status: 'Pending',
      isVisible: 'true',
      sortColumn: 'added',
      sortDirection: 'desc'
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }
}
