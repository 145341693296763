import { Injectable } from '@angular/core';
import { Corporation } from '../../interfaces/corporations/corporation';
import { Order } from '../../interfaces/orders/order';
import { User } from '../../interfaces/users/user';
import { UserGroup } from '../../interfaces/user-groups/user-group';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private _corporation: Corporation | null = null;
  private _userGroup: UserGroup | null = null;
  private _user: User | null = null;

  getCorporation() { 
    return this._corporation; 
  } 
  setCorporation(corporation: Corporation | null) { 
    this._corporation = corporation; 
  }

  getUserGroup() { 
    return this._userGroup; 
  } 
  setUserGroup(userGroup: UserGroup | null) { 
    this._userGroup = userGroup; 
  }

  getUser() { 
    return this._user; 
  } 
  setUser(user: User | null) { 
    this._user = user; 
  }
}
