<mat-sidenav-container>
    <mat-sidenav [opened]="isSidenavOpen" mode="side">
        <div class="mat-sidenav-wrapper">
            <div class="mat-nav-wrapper">
                <mat-nav-list mat-button>
                    <a mat-list-item *ngFor="let item of navItems" [routerLink]="item.link"
                        routerLinkActive="activeBackground ">
                        <span matListItemIcon><mat-icon>{{ item.icon }}</mat-icon></span>
                        <span matListItemTitle routerLinkActive="activeText">{{ item.label }}</span>
                    </a>
                </mat-nav-list>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="spacer-small"></div>
        <div class="padding-sides-15">
            <lib-search-bar (search)="onSearch($event)"></lib-search-bar>
        </div>
        <div toastContainer aria-live="polite"></div>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>