import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class Base64Service {
    constructor() { }

    convertToBase64(file: File): Observable<string> {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Observable(observer => {
            reader.onload = () => {
                var rawResult = reader.result as string;
                var splitResult = rawResult.split("base64,");
                observer.next(splitResult.length > 1 ? splitResult[1] : rawResult);
                observer.complete();
            };
            reader.onerror = (error) => {
                observer.error(error);
            };
        });
    }
}