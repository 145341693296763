export enum eOrderType {
    notSet = "NotSet",
    lien = "Lien",
    renewal = "Renewal",
    discharge = "Discharge",
    amendment = "Amendment",
    debtorSearch = "DebtorSearch",
    assetSearch = "AssetSearch",
    repairLien = "RepairLien",
    standaloneRenewal = "StandaloneRenewal",
    standaloneDischarge = "StandaloneDischarge",
    certifiedDebtorSearch = "CertifiedDebtorSearch"
}

export enum eOrderTypeGroup {
    PPSARegistration = 1,
    PPSASearch = 2,
    StandalonePPSARegistration = 3,
    PPSAChangeManagement = 4
}

export enum ePartyType {
    businessDebtor = "BusinessDebtor",
    individualDebtor = "IndividualDebtor",
    businessSecuredParty = "BusinessSecuredParty",
    individualSecuredParty = "IndividualSecuredParty",
    businessRegisteringAgent = "BusinessRegisteringAgent",
    individualRegisteringAgent = "IndividualRegisteringAgent",
    businessDealer = "BusinessDealer",
    individualDealer = "IndividualDealer"
}

export enum eAssetType {
    None = 0,
    MotorVehicle = 1,
    Trailer = 2,
    Boat = 3,
    MobileHome = 4,
    OutboardMotor = 5,
    AircraftRegisteredInCanada = 6,
    AircraftNonRegisteredInCanada = 7,
    ManufacturedHome = 8,
    FarmVehicle = 9,
    Motorcycle = 10,
    Bus = 11,
    MiniBus = 12,
    Taxi = 13,
    AllTerrainVehicle = 14,
    SnowmobilePost1988 = 15,
    PassengerVehicle = 16,
    EmergencyVehicle = 17,
    CommercialVehicle = 18,
    Other = 19,
    AircraftCanada = 20,
    AircraftForeign = 21,
    AircraftAirframeRegisteredInCanada = 22,
    AircraftAirframeNotRegisteredInCanada = 23,
    Aircraft = 24,
    AircraftDOT = 25,
    AircraftSerial = 26,
    TrailerOrSemiTrailer = 27,
    MotorHome = 28,
}

export const assetTypeLookup: { [key: string]: eAssetType } = {
    MotorVehicle: eAssetType.MotorVehicle,
    Trailer: eAssetType.Trailer,
    Boat: eAssetType.Boat,
    MobileHome: eAssetType.MobileHome,
    OutboardMotor: eAssetType.OutboardMotor,
    AircraftRegisteredInCanada: eAssetType.AircraftRegisteredInCanada,
    AircraftNonRegisteredInCanada: eAssetType.AircraftNonRegisteredInCanada,
    ManufacturedHome: eAssetType.ManufacturedHome,
    FarmVehicle: eAssetType.FarmVehicle,
    Motorcycle: eAssetType.Motorcycle,
    Bus: eAssetType.Bus,
    MiniBus: eAssetType.MiniBus,
    Taxi: eAssetType.Taxi,
    AllTerrainVehicle: eAssetType.AllTerrainVehicle,
    SnowmobilePost1988: eAssetType.SnowmobilePost1988,
    PassengerVehicle: eAssetType.PassengerVehicle,
    EmergencyVehicle: eAssetType.EmergencyVehicle,
    CommercialVehicle: eAssetType.CommercialVehicle,
    Other: eAssetType.Other,
    AircraftCanada: eAssetType.AircraftCanada,
    AircraftForeign: eAssetType.AircraftForeign,
    AircraftAirframeRegisteredInCanada: eAssetType.AircraftAirframeRegisteredInCanada,
    AircraftAirframeNotRegisteredInCanada: eAssetType.AircraftAirframeNotRegisteredInCanada,
    Aircraft: eAssetType.Aircraft,
    AircraftDOT: eAssetType.AircraftDOT,
    AircraftSerial: eAssetType.AircraftSerial,
    TrailerOrSemiTrailer: eAssetType.TrailerOrSemiTrailer,
    MotorHome: eAssetType.MotorHome
  };

export enum eOrderStatusType {
    draft = 1,
    pending = 2,
    complete = 3,
    invalid = 4,
    requestedAtRegistry = 5,
    failedAutomation = 6,
    manualFulfillment = 7,
    outOfRegistryHours = 8,
    awaitingHighVolumeResults = 9,
    historicalDraft = 10,
    historical = 11,
    registryOutage = 12,
    exception = 13,
    errorCorrection = 14,
    documentProcessing = 15,
    cancelled = 16,
}

export enum eGeneration {
    Jr = "Jr",
    Sr = "Sr",
    Esquire = "Esquire",
    First = "First",
    Second = "Second",
    Third = "Third",
    Fourth = "Fourth",
    Fifth = "Fifth",
    Sixth = "Sixth",
    Elder = "Elder",
    Younger = "Younger"
}

export enum eMatSelectAppearance {
    legacy = "legacy",
    standard = "standard",
    fill = "fill",
    outline = "outline"
}

export enum eLogSeverity {
    Debug = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

export enum eUserType {
    Api = 1,
    Portal = 2,
}

export enum eQCFormType {
    none = 0,
    RHa = "RHa",
    RDd = "RDd",
    RDe = "RDe",
    RG34 = "RG34",
    RG41 = "RG41",
    RV = "RV",
    RRa = "RRa",
    RHf = "RHf",
    RDf = "RDf"
}

export enum eSystemEventType {
    ScheduledMaintenance = 1,
    RegistryOutage = 2
}

export enum eOrderChangeOperation {
    Replace = "Replace",
    Add = "Add",
    Remove = "Remove"
}

export enum eDatabaseType {
    Primary = 1,
    Customer = 2
}

export enum eAmendmentType {
    change = "Change",
    correction = "Correction"
}

export enum eDischargeType {
    totalDischarge = "TotalDischarge",
    agreementToDischarge = "AgreementToDischarge"
}

export enum eEnvelopeStatusType {
    open = "Open",
    sealed = "Sealed",
    processingError = "ProcessingError",
    importError = "ImportError",
    validationError = "ValidationError",
    imported = "Imported",
    submitted = "Submitted"
}