import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

// This should keep us from losing a day on parsed dates in datepickers.
@Injectable()
export class RegHubDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    const parsedDate = super.parse(value);
    if (parsedDate) {
      parsedDate.setHours(parsedDate.getHours() + 10);
    }
    return parsedDate;
  }
}