import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ListComponent } from '../../ui-common/list-component';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { ProductPricing } from '../../interfaces/product-pricing/product-pricing';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';


@Injectable({
    providedIn: 'root'
})
export class ProductPricingRepositoryService extends PaginatedRepository<ProductPricing> {
    protected override _baseLink: string;

    constructor(
      http: HttpClient,
      messageService: ToastrService,
      @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      super(
          http,
          messageService);
    
          this._currentParams = new HttpParams()
            .set('pageSize', 10);
            
          this._baseLink = `${this.config.rootUrl}/ProductPricing`;
      }
}