<h4>Requesting User Details</h4>
<div class="flex-container">
    <span class="flex-item">User:</span><span class="flex-item-2">{{ user?.userName ?? "" }} ({{ userType }})</span>
</div>
<div class="flex-container">
    <span class="flex-item">User Group ID:</span><span class="flex-item-2">{{ order.userGroupID }}</span>
</div>
<div class="flex-container">
    <span class="flex-item">Corporation ID:</span><span class="flex-item-2">{{ order.orderGroup?.corporationID ?? '' }}</span>
</div>
