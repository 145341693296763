import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'pacificDate'
})
export class PdtDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: any, format: string = 'yyyy-MM-dd HH:mm'): any {
    const timezone = 'America/Los_Angeles';
    return this.datePipe.transform(value, format, timezone);
  }
}