import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsRepositoryService, eEnvelopeStatusType, Envelope, EnvelopeRepositoryService, eOrderStatusType, ListComponent, OrderStateRepositoryService } from 'reg-hub-common';
import { concatMap, forkJoin, interval, Observable, Subject, switchMap, take, takeUntil, takeWhile, timer } from 'rxjs';
import { EnvelopeOrdersPopupComponent } from '../envelope-orders-popup/envelope-orders-popup.component';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-envelopes',
  templateUrl: './envelopes.component.html',
  styleUrls: ['./envelopes.component.css']
})
export class EnvelopesComponent extends ListComponent<Envelope> implements OnInit, OnDestroy {
  displayedColumns = [
    "envelopeId",
    "added",
    "numOrders",
    "icon",
    "status",
    "document",
    "actions"
  ]

  private onDestroy$ = new Subject<void>();
  protected pageSize = 4;
  protected includeSubmitted = false;

  importing = new Map<string, boolean>();

  constructor(
    private dialog: MatDialog,
    protected envelopesRepository: EnvelopeRepositoryService,
    private documentsRepository: DocumentsRepositoryService,
    private orderStateService: OrderStateRepositoryService,
    private router: Router) { 
      super(envelopesRepository);
    }

  ngOnInit(): void {
    let params = new HttpParams()
      .set('pageSize', this.pageSize);

    this.repo.getFirstPage(this.setParamsWithoutSubmitted(params));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setParamsWithoutSubmitted(params: HttpParams): HttpParams {
    return params
      .append('envelopeStatus', eEnvelopeStatusType.open)
      .append('envelopeStatus', eEnvelopeStatusType.sealed)
      .append('envelopeStatus', eEnvelopeStatusType.processingError)
      .append('envelopeStatus', eEnvelopeStatusType.importError)
      .append('envelopeStatus', eEnvelopeStatusType.validationError)
      .append('envelopeStatus', eEnvelopeStatusType.imported);
  }

  setParamsWithSubmitted(params: HttpParams): HttpParams {
    return this.setParamsWithoutSubmitted(params)
      .append('envelopeStatus', eEnvelopeStatusType.submitted);
  }

  isSubmittedFilterChange() {
    if(this.includeSubmitted) {
      this.repo.filter(this.setParamsWithSubmitted(new HttpParams()));
    } else {
      this.repo.filter(this.setParamsWithoutSubmitted(new HttpParams()));
    }
  }

  viewLogs(envelope: Envelope) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['logs'], { queryParams: { tab: 'changes', entityID: envelope.id, entityName: 'Envelope' } })
    );

    window.open(url, '_blank');
  }

  viewOrders(envelope: Envelope) {
    this.dialog.open(EnvelopeOrdersPopupComponent, {
      data: envelope,
      minWidth: '800px'
    })
  }

  downloadDocument(envelope: Envelope) {
    if(!envelope.document) {
      return;
    }

    this.documentsRepository.viewAndDownloadDocumentWithType(envelope.document.id, "application/xml");
  }

  openEnvelope(envelope: Envelope) {
    this.envelopesRepository.updateEnvelopeStatus(envelope.id, eEnvelopeStatusType.open)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.envelopesRepository.refresh());
  }

  sealEnvelope(envelope: Envelope) {
    this.envelopesRepository.updateEnvelopeStatus(envelope.id, eEnvelopeStatusType.sealed)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.envelopesRepository.refresh());
  }

  importEnvelope(envelope: Envelope) {
    if(envelope.envelopeStatus === eEnvelopeStatusType.open ||
      envelope.envelopeStatus === eEnvelopeStatusType.submitted) {
      return;
    }

    this.importing.set(envelope.id, true);
    this.envelopesRepository.importEnvelope(envelope.id, "qc_registrations").subscribe();
    
    let refresh$ = interval(7500)
      .pipe(
        takeUntil(this.onDestroy$),
        takeUntil(timer(60000)),
        switchMap(() => this.envelopesRepository.refreshSubscribable()),
        takeWhile(envelopes => envelopes.find(env => env.id === envelope.id)?.statusLastUpdated === envelope.statusLastUpdated));

    timer(40000)
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(() => refresh$))
      .subscribe({ complete: () => this.importing.set(envelope.id, false) });
  }

  isImporting(envelope: Envelope) {
    return this.importing.get(envelope.id);
  }

  submitEnvelope(envelope: Envelope) {
    this.envelopesRepository.updateEnvelopeStatus(envelope.id, eEnvelopeStatusType.submitted)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.envelopesRepository.refresh());
  }

  shouldDisableOpenAction(envelope: Envelope) {
    return this.isImporting(envelope) || envelope.envelopeStatus === eEnvelopeStatusType.open;
  }

  shouldDisableSealAction(envelope: Envelope) {
    return this.isImporting(envelope) || envelope.envelopeStatus !== eEnvelopeStatusType.open;
  }

  shouldDisableImportAction(envelope: Envelope) {
    return this.isImporting(envelope) || envelope.envelopeStatus === eEnvelopeStatusType.open || envelope.envelopeStatus === eEnvelopeStatusType.submitted;
  }

  shouldDisableSubmitAction(envelope: Envelope) {
    return this.isImporting(envelope) || envelope.envelopeStatus !== eEnvelopeStatusType.imported;
  }

  getIcon(envelope: Envelope) {
    switch(envelope.envelopeStatus) {
      case eEnvelopeStatusType.open:
        return 'folder_open';
      case eEnvelopeStatusType.sealed:
        return 'folder';
      case eEnvelopeStatusType.importError:
      case eEnvelopeStatusType.processingError:
      case eEnvelopeStatusType.validationError:
        return 'error_outline';
      case eEnvelopeStatusType.imported:
        return 'history';
      case eEnvelopeStatusType.submitted:
        return 'check_circle_outline';
      default:
        return '';
    }
  }
}
