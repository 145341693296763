import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { RegistryCredential } from '../../interfaces/registry-credential';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';


@Injectable({
  providedIn: 'root'
})
export class RegistryCredentialsRepositoryService extends PaginatedRepository<RegistryCredential> {

  protected override _baseLink: string;

  constructor(
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
    http: HttpClient,
    messageService: ToastrService
  ) {
    super(http, messageService);

    this._baseLink = `${this.config.rootUrl}/RegistryCredentials`;
  }

  public createRegistryCredential(automationName: string, credential: any): Observable<RegistryCredential> {
    return this.http.post<RegistryCredential>(`${this._baseLink}/${automationName}`, credential);
  }

  public purgeRegistryCredential(credential: RegistryCredential): Observable<any> {
    return this.http.delete(`${this._baseLink}/${credential.automationName}/${credential.id}`);
  }
}
