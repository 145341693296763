import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AdminAuthService } from './services/auth/admin-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private authService: AdminAuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isLoggedIn()) {
      const userRole = this.authService.getRoleFromToken() ?? "";

      if (userRole == 'Admin') {
        return true;
      }
      // You don't have access to this portal, log you out.
      this.authService.logOut();
      return false;
    } else {
      // this will just force a log in.
      return true;
    }
  }
}