<div class="flex-container">
    <div class="flex-item">
        <mat-list>
            <mat-list-item>
                <span matListItemTitle>ID</span>
                <span matListItemLine>{{ order.id }}</span>
            </mat-list-item>
            <mat-list-item>
                <span matListItemTitle>Date Requested</span>
                <span>{{ order.dateRequested | pacificDate }}</span>
            </mat-list-item>
            <mat-list-item>
                <span matListItemTitle>Visibility</span>
                <span matListItemLine>{{ order.isVisible ? 'Visible' :
                    'Deleted'
                    }}</span>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="flex-item">
        <mat-list class="flex-item">
            <mat-list-item>
                <span matListItemTitle>Search Criteria</span>
                <span>{{ getSearchCriteria(order) }}</span>
            </mat-list-item>
        </mat-list>
    </div>
</div>