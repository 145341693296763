import { Component, Input } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Lien } from '../../interfaces/liens/lien';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-lien-results',
  templateUrl: './lien-results.component.html',
  styleUrls: ['./lien-results.component.css']
})
export class LienResultsComponent {
  order!: Order;
  lien!: Lien | null | undefined;

  private unsubscribe$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(order => {
      this.order = order
      this.lien = order.lien;
    });
  }
  
  ngOnDestroy(): void {
      // Signal all subscriptions to complete
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }
}