import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order, OrderRepositoryService } from 'reg-hub-common';

@Component({
  selector: 'app-failed-orders',
  templateUrl: './failed-orders.component.html'
})
export class FailedOrdersComponent implements OnInit {

  @Input() data: Order[] = [];
  @Input() total: number = 0;
  @Input() loading: boolean = false;

  protected displayedColumns = [
    'referenceNumber',
    'status',
    'orderTypeID',
    'jurisdiction'
  ]

  constructor(
    protected repo: OrderRepositoryService,
    protected router: Router) { }

  ngOnInit(): void { }

  protected onViewAll() {
    let params = {
      status: ['FailedAutomation','Invalid','RegistryOutage','ErrorCorrection'],
      isVisible: 'true',
      sortColumn: 'added',
      sortDirection: 'desc'
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }
}
