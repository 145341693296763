import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RegHubApiResult } from '../../interfaces/reghubapi-result';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { ExternalOrderNumber } from '../../interfaces/external-order-number/external-order-number';

@Injectable({
    providedIn: 'root'
})
export class ExternalOrderNumberRepositoryService {
    protected _baseLink: string;

    constructor(
        private http: HttpClient,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        this._baseLink = `${this.config.rootUrl}`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getDocument(orderID: string): Observable<ExternalOrderNumber> {
        return this.http.get<ExternalOrderNumber>(`${this._baseLink}/Orders/${orderID}/ExternalOrderNumber`);
    }

    public createExternalOrderNumber = (orderID: string, externalOrderNumber: ExternalOrderNumber) => {
        return this.http.post<ExternalOrderNumber>(`${this._baseLink}/Orders/${orderID}/ExternalOrderNumber`, externalOrderNumber);
    }

    public updateExternalOrderNumber = (orderID: string, externalOrderNumber: ExternalOrderNumber) => {
        return this.http.put(`${this._baseLink}/Orders/${orderID}/ExternalOrderNumber`, externalOrderNumber, this.generateHeaders());
    }
}