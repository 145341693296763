import { Inject, Injectable } from '@angular/core';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { Envelope } from '../../interfaces/envelopes/envelope';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { Observable } from 'rxjs';
import { PatchDocument } from '../../interfaces/patch-document';
import { eEnvelopeStatusType } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class EnvelopeRepositoryService extends PaginatedRepository<Envelope> {
  protected override _baseLink: string;

  constructor(
    http: HttpClient,
    messageService: ToastrService,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
    super(http, messageService);
    this._baseLink = `${config.rootUrl}/Envelopes`;
  }

  public patchEnvelope(envelopeId: string, patchDocument: PatchDocument[]): Observable<void> {
    return this.http.patch<void>(`${this._baseLink}/${envelopeId}`, patchDocument);
  }

  public importEnvelope(envelopeId: string, queue: string): Observable<void> {
    return this.http.post<void>(`${this._baseLink}/${envelopeId}/Import`, null, { params: { queueName: queue }});
  }

  public updateEnvelopeStatus(envelopeId: string, newStatus: eEnvelopeStatusType): Observable<void> {
    return this.patchEnvelope(envelopeId, [{ "op": "replace", "path": "EnvelopeStatus", "value": newStatus }]);
  }

  public removeOrderFromEnvelope(envelopeId: string, orderId: string) {
    return this.http.delete<void>(`${this._baseLink}/${envelopeId}/Orders/${orderId}`);
  }
}
