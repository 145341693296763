import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OrderWaitTime } from '../../interfaces/order-wait-times/order-wait-time';
import { OrderWaitTimeForCreation } from '../../interfaces/order-wait-times/order-wait-time-for-creation';
import { OrderWaitTimeForUpdate } from '../../interfaces/order-wait-times/order-wait-time-for-update';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
    providedIn: 'root'
})
export class OrderWaitTimesRepository extends PaginatedRepository<OrderWaitTime> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLink = `${config.rootUrl}/OrderWaitTimes`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    // CRUD Operations ---------------
    public getOrderWaitTime = (orderWaitTimeID: string) => {
        return this.http.get<OrderWaitTime>(`${this._baseLink}/${orderWaitTimeID}`, this.generateHeaders());
    }

    public createOrderWaitTime = (orderWaitTime: OrderWaitTimeForCreation) => {
        return this.http.post<OrderWaitTime>(this._baseLink, orderWaitTime, this.generateHeaders());
    }

    public putOrderWaitTime = (orderWaitTimeID: string, orderWaitTime: OrderWaitTimeForUpdate) => {
        return this.http.put<OrderWaitTime>(`${this._baseLink}/${orderWaitTimeID}`, orderWaitTime, this.generateHeaders());
    }

    public deleteOrderWaitTime = (orderWaitTimeID: string) => {
        return this.http.delete(`${this._baseLink}/${orderWaitTimeID}`, this.generateHeaders());
    }
}