import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { SLAReport } from '../../../interfaces/reports/sla-report';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../../reg-hub-common-config';


@Injectable({
  providedIn: 'root'
})
export class SLAReportRepositoryService {
  constructor(
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
    private http: HttpClient,
    private messageService: ToastrService) {
  }

  public getAdminSLAReport(filters: HttpParams): Observable<SLAReport[]> {
    return this.http.get<SLAReport[]>(`${this.config.rootUrl}/Reports/SLA`, { params: filters });
  }

  public getCorporationSLAReport(corporationID: string): Observable<SLAReport[]> {
    return this.http.get<SLAReport[]>(`${this.config.rootUrl}/Corporations/${corporationID}/Reports/SLA`);
  }
}
