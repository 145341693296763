<div class="padding">
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <div class="flex-container-space-between">
                    <h3>Envelopes Overview</h3>
                    <mat-checkbox [(ngModel)]="includeSubmitted" (change)="isSubmittedFilterChange()">
                        Show Submitted
                    </mat-checkbox>
                </div>

                <!-- Databases overview table -->
                <table mat-table [dataSource]="this.repo.resource$" multiTemplateDataRows
                    *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0">

                    <ng-container matColumnDef="envelopeId">
                        <th mat-header-cell *matHeaderCellDef id="envelopeId">
                            Envelope ID
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <button mat-button (click)="viewLogs(envelope)">{{
                                envelope.id }}</button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="added">
                        <th mat-header-cell *matHeaderCellDef id="added">
                            <div class="flex-container-center">
                                Creation Date
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                {{ envelope.added | pacificDate }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numOrders">
                        <th mat-header-cell *matHeaderCellDef id="numOrders">
                            <div class="flex-container-center">
                                Orders
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <a mat-flat-button color="primary" (click)="viewOrders(envelope)">
                                    {{ envelope.envelopeOrders.length }}
                                </a>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="icon">
                        <th mat-header-cell *matHeaderCellDef id="icon"> </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <mat-icon *ngIf="!isImporting(envelope); else importing">{{ getIcon(envelope) }}</mat-icon>
                                <ng-template #importing>
                                    <div class="spinner-inline-container">
                                        <div class="spinner-inline"> </div>
                                    </div>
                                </ng-template>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef id="status">
                            <div class="flex-container-center">
                                Envelope Status
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center" *ngIf="!isImporting(envelope); else importing">
                                {{ envelope.envelopeStatus }}
                            </div>
                            <ng-template #importing>
                                <div class="flex-container-center">
                                    Importing...
                                </div>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="document">
                        <th mat-header-cell *matHeaderCellDef id="document">
                            <div class="flex-container-center">
                                Document
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <a mat-flat-button color="primary" (click)="downloadDocument(envelope)"
                                    [disabled]="!envelope.document">Download</a>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef id="actions"> </th>
                        <td mat-cell *matCellDef="let envelope">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Envelope actions menu">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openEnvelope(envelope)" [disabled]="shouldDisableOpenAction(envelope)">
                                  <span>Open</span>
                                </button>
                                <button mat-menu-item (click)="sealEnvelope(envelope)" [disabled]="shouldDisableSealAction(envelope)">
                                  <span>Seal</span>
                                </button>
                                <button mat-menu-item (click)="importEnvelope(envelope)" [disabled]="shouldDisableImportAction(envelope)">
                                  <span>Import</span>
                                </button>
                                <button mat-menu-item (click)="submitEnvelope(envelope)" [disabled]="shouldDisableSubmitAction(envelope)">
                                  <span>Submit</span>
                                </button>
                              </mat-menu>                              
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator *ngIf="(repo.resourceCount$ | async) ?? 0 > pageSize" [hidePageSize]="true"
                    [pageSize]="pageSize" [length]="this.repo.resourceCount$ | async"
                    [pageIndex]="this.repo.pageNum$ | async" showFirstLastButtons="true"
                    (page)="page($event)"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>