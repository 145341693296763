<div class="padding" *ngIf="!loading">
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <h3>Automation Monitor</h3>

                <mat-tab-group animationDuration="0ms">
                    <mat-tab label="Queues">
                        <app-queue-monitors></app-queue-monitors>
                    </mat-tab>
                    <mat-tab label="Scheduled">
                        <app-scheduled-monitors></app-scheduled-monitors>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>