<h2 mat-dialog-title>Order Group History (Group ID: {{ order.orderGroup!.id }})</h2>
<mat-dialog-content>
    <table mat-table [dataSource]="orderHistory" multiTemplateDataRows class="mat-elevation-z2">
        <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> Ref No. </th>
            <td mat-cell *matCellDef="let history"> {{ history.referenceNumber }} </td>
        </ng-container>

        <ng-container matColumnDef="orderTypeID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="orderTypeID"> Type </th>
            <td mat-cell *matCellDef="let order"> {{order.orderTypeID}} </td>
        </ng-container>

        <ng-container matColumnDef="orderStatusTypeID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="orderStatusTypeID"> Status
            </th>
            <td mat-cell *matCellDef="let order"> {{order.orderStatusTypeID}} </td>
        </ng-container>

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="country"> Country </th>
            <td mat-cell *matCellDef="let order"> {{order.country}} </td>
        </ng-container>

        <ng-container matColumnDef="jurisdiction">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="jurisdiction"> Jurisdiction
            </th>
            <td mat-cell *matCellDef="let order"> {{order.jurisdiction}} </td>
        </ng-container>

        <ng-container matColumnDef="dateRequested">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="dateRequested"> Date Requested
            </th>
            <td mat-cell *matCellDef="let order">
                {{order.dateRequested | pacificDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="dateUpdated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="dateUpdated"> Date Updated
            </th>
            <td mat-cell *matCellDef="let order">
                {{order.orderStatusUpdatedDate | pacificDate }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'grey': row.id == order.id}" (click)="navigateToOrder(row.id)">
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>