import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { RegistryHours } from '../../interfaces/registry-hours/registry-hours';
import { RegistryHoursForCreation } from '../../interfaces/registry-hours/registry-hours-for-creation';
import { RegistryHoursForUpdate } from '../../interfaces/registry-hours/registry-hours-for-update';

@Injectable({
  providedIn: 'root'
})
export class RegistryHoursRepositoryService extends PaginatedRepository<RegistryHours> {

  protected override _baseLink: string;

  constructor(
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
    http: HttpClient,
    messageService: ToastrService
  ) {
    super(http, messageService);

    this._baseLink = `${this.config.rootUrl}/RegistryHours`;
  }

  public getRegistryHours(registryHoursID: string): Observable<RegistryHours> {
    return this.http.get<RegistryHours>(`${this._baseLink}/${registryHoursID}`);
  }

  public createRegistryHours(registryHours: RegistryHoursForCreation): Observable<RegistryHours> {
    return this.http.post<RegistryHours>(this._baseLink, registryHours);
  }

  public updateRegistryHours(registryHoursID: string, registryHours: RegistryHoursForUpdate): Observable<RegistryHours> {
    return this.http.put<RegistryHours>(`${this._baseLink}/${registryHoursID}`, registryHours);
  }

  public deleteRegistryHours(registryHoursID: string): Observable<any> {
    return this.http.delete(`${this._baseLink}/${registryHoursID}`);
  }
}
