<div class="flex-container" *ngIf="order.jurisdiction == 'SK'">
    <span class="flex-item">RIN:</span>
    <span class="flex-item-2">{{ lien?.rin }}</span>
</div>
<div class="flex-container" *ngIf="order.jurisdiction == 'ON'">
    <span class="flex-item">File Number:</span>
    <span class="flex-item-2">{{ lien?.fileNumber }}</span>
</div>
<div class="flex-container">
    <span class="flex-item">Registration Number:</span>
    <span class="flex-item-2">{{ lien?.registrationNumber }}</span>
</div>
<div class="flex-container">
    <span class="flex-item">Registration Date:</span>
    <span class="flex-item-2">{{ lien?.registrationDate | pacificDate: "yyyy-MM-dd" }}</span>
</div>
<div class="flex-container">
    <span class="flex-item">Expiry Date:</span>
    <span class="flex-item-2">{{ (lien?.isInfiniteTerm ?? false) ? "NO EXPIRY DATE" : lien?.expiryDate | pacificDate: "yyyy-MM-dd" }}</span>
</div>